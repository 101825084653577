<template>
  <div class="card card-body shadow-sm service-category">

      <app-basic-table
        ref="basic_table"
        :table-name="$t('users.user list')"
        :setting-columns="columns"
        :filters.sync="filters"
        :endpoint-list="ENDPOINT.SERVICE_CATEGORY_LIST"
        @resetAllSearch="onResetAllSearch"
        @sortRowTable="onSortRowTable"
        @searched="onSearch"
        :listCanSort="true"
      >
        <template v-slot:filters>
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-6">
              <div class="form-group">
                <label>{{ filters.apartment_id_equal.label }}</label>
                <app-select :name="filters.apartment_id_equal.name"
                            input-style="normal"
                            v-model="filters.apartment_id_equal.value"
                            :options-data="meta.apartments"

                />
              </div>
            </div>
          </div>
        </template>
        <template v-slot:table-menu-right>
          <button @click="handleBtnCreateClick()" class="btn btn-warning">
            {{ $t("common.create") }}
          </button>
        </template>
        <template v-slot:body-cell-edit="props">
          <td class="app-align-middle text-center app-p-none">
            <button
              @click="handleBtnUpdateClick(props.row)"
              class="btn btn-primary"
            >
              {{ $t("common.edit") }}
            </button>
          </td>
        </template>
        <template v-slot:body-cell-apartment_id="props">
          <td class="app-align-middle">
            <p :data-original-title="props.row.apartment_name" style="color: #007bff;"
               @click="handlerApartmentNameClick(props.row)" class="apartment_name app-table-p app-cell-tooltip mb-0">
              {{props.row.apartment_name}}
            </p>
          </td>
        </template>
      </app-basic-table>
  </div>
</template>

<script>
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import {ENDPOINT} from "../../../constants/api";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import {INFORMATION_CONSTANT} from "../../../constants/information";
  import {RESERVATION_CONSTANT} from "../../../constants/reservation";
  export default {
    name: "ReservationListPage",
    components: {
      "app-basic-table": AppBasicTable,
      AppDateRangePicker,
    },
    data() {
      return {
        idCache: null,
        filters: {
          apartment_id_equal: {
            name: "apartment_id",
            condition: "equal",
            label: this.$t("common.mansion")
          },
        },
        columns: [
          {name: "order_disp_crowded", label: this.$t("common.number_no"), sortable: true, textAlign: 'text-center'},
          // {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
          {name: "apartment_id", label: this.$t("common.mansion"), sortable: true},
          {name: "name", label: this.$t("service_category.category_name"), sortable: true},
          {name: "time", label: '運営時間'},
          {name: "note", label: this.$t("service_category.category_note")},
          {name: "edit", label: this.$t("common.edit"), textAlign: 'text-center'},
        ],
        meta: {
          apartments: [],
        },
      };
    },
    watch : {
      // "filters.apartment_id_equal.value" : function(value) {
      //   this.getParamsFilters({apartment_id : value})
      // }
    },

    methods: {
      onResetAllSearch() {
        this.$router.push({name: this.$route.name,
          query: {
            'perPage' : '99999',
            'sortBy' : 'apartment_id',
            'filters.apartment_id.equal': 'all'
          }
        })
      },
      onSearch() {
        // this.getTourSelectName();
      },
      async onSortRowTable(sortIndex) {
        await this.$request.post(this.ENDPOINT.SERVICE_CATEGORY_RE_ORDER, {'sortIndex': sortIndex, 'apartment_id': this.filters.apartment_id_equal.value, 'order_by': this.$route.query.sortType});
        this.$refs.basic_table.getEntries(true)
      },
      handleBtnCreateClick() {
        this.$router.push({name: this.ROUTES.ADMIN.SERVICE_CATEGORY_CREATE})
      },
      handleBtnUpdateClick(entry) {
        this.$router.push({name: this.ROUTES.ADMIN.SERVICE_CATEGORY_EDIT, params: {id: entry.id}})
      },

      handlerApartmentNameClick(entry) {
        let routeApartment = this.$router.resolve({name: this.ROUTES.ADMIN.APARTMENT_EDIT, params: {id: entry.apartment_id}});
        window.open(routeApartment.href, '_blank');
      },
      getParamsFilters() {
        this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
          this.meta = {
            apartments: res.data.apartments,
          }
          this.lastApartment = res.data.apartments[0];
          this.meta.apartments.unshift({
            id: "",
            name: ""
          })
        })
      }
    },
    mounted: function () {
      this.getParamsFilters()
    },
  }
</script>
